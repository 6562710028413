@import "~bootstrap/scss/functions";
@import "../../../assets/styles/vars";
@import "~bootstrap/scss/mixins";

.team-member {
  position: relative;
  margin-bottom: $grid-gutter-width;

  >img {
    filter: grayscale(.9);
    transition: filter .3s ease-in-out;
  }
  &:hover {
    > img {
      filter: grayscale(0);
    }
  }
  > div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: $grid-gutter-width;
  }
}