@import "fonts";
@import "libs";

@import "theme/index";

@import "media"; // Always at the end

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}