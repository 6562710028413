html {
  font-size: $font-size-base;
}

body {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

.jumbotron {
  @include border-radius(0);
  padding: $jumbotron-padding;
  @include media-breakpoint-down(md) {
    padding: 2rem;
  }
}

label {
  text-transform: uppercase;
  font-size: .8rem;
  font-weight: $font-weight-bold;
  letter-spacing: .05em;
}

p {
  @include media-breakpoint-up(md) {
    font-size: $font-size-lg;
  }
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 500;
  margin-bottom: 1rem;
}