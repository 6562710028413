.img-shadow {
  box-shadow: darken($home-bg, 10%) 0 1rem 4rem -1rem;
}

.img-border {
  border: 12px solid rgba(0, 0, 0, .3);
}

.block-link {
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.pointer {
  cursor: pointer;
}

.scale-13 {
  @include media-breakpoint-up(lg) {
    transform: scale(1.3, 1.3);
  }
}

.scale-15 {
  transform: scale(1.5, 1.5);
}

.f-screen {
  min-height: 100vh;
}

.my-15 {
  padding-top: 15rem;
  padding-bottom: 15rem;
  overflow: hidden;
}

.op-5 {
  opacity: .5;
}

.b-link {
  color: $body-color;

  &:hover {
    color: $body-color;
    text-decoration: none;
  }

  &:last-child {
    margin-bottom: 0 !important;
  }
}

.overflow-x-auto {
  overflow-x: auto;
}