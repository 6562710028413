@import "~bootstrap/scss/functions";
@import "../../../assets/styles/vars";
@import "~bootstrap/scss/mixins";

.icon-list {
  max-width: 650px;
  margin: 0 auto;

  [class*="fa"] {
    display: inline-block;
    margin: $grid-gutter-width/2;
    opacity: .5;
    transition: opacity .3s;

    &:hover {
      opacity: 1;
    }
  }
}
