.home-brands {
  svg {
    width: 100%;
  }
}

.strapline {
  @media screen and (max-width: 1200px) {
    font-size: calc(1.225rem + 3vw);
  }
}
